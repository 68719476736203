export const FirebaseConfig = {
	"projectId": "carwashservice-74a00",
	"appId": "1:346218898209:web:32cf0ed41591e153489460",
	"databaseURL": "https://carwashservice-74a00-default-rtdb.firebaseio.com",
	"storageBucket": "carwashservice-74a00.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDrOaz4OBn_YKeHa1Z0pwDhFtprjfP-R2s",
	"authDomain": "carwashservice-74a00.firebaseapp.com",
	"messagingSenderId": "346218898209",
	"measurementId": "G-D2C11DLVHY"
};